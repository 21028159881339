import PlaceholderCard from "@/src/components/app-items/loading/placeholder-card.component";
import { Suspense } from "react";

interface IProps {
  children?: any;
}

const SuspenseWrap = ({ children }: IProps) => {
  const placeholderItem = (
    <div className="grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {Array.from({ length: 8 }).map((_, i) => (
        <PlaceholderCard key={i} />
      ))}
    </div>
  );

  return <Suspense fallback={placeholderItem}>{children}</Suspense>;
};

export default SuspenseWrap;

import Image from "next/image";
import type { ComponentProps } from "react";
export const runtime = "edge";
export default function BlurImage(props: ComponentProps<typeof Image>) {
  return (
    <Image
      className="relative h-auto w-full object-cover"
      {...props}
      alt={props.alt}
      fill={props.height || props.width ? undefined : true}
      sizes="(max-width: 768px) 100vw, (max-width: 1200px) 50vw, 33vw"
    />
  );
}

export const PORTAL_ROUTE = {
  property: {
    projects: "/projects",
    projectDetail: "/project/form/[slug]",
    propertyDetail: "/property/form/[slug]",
  },
};

export const APP_ROUTE = {
  social: {
    facebook: "https://www.facebook.com/1hourdev/",
    twitter: "https://twitter.com/dfgefgef11",
    instagram: undefined,
  },
  staticPage: {
    about: "/about",
    privacy: "/privacy-policy",
    terms: "/terms-conditions",
  },
  property: {
    projects: "/projects",
    projectDetail: "/project/[slug]",
    propertyDetail: "/property/[slug]",
  },
  tool: {
    tools: "/tools",
    stripeGenerate: "/tools/stripe",
    twitterGenerate: "/tools/x-revenue",
    fbDownload: "/tools/fb-extract",
    keywordAnalyze: "/tools/keyword-analyze",
  },
  resource: {
    resources: "/resources",
    stripeGenerate: "/tools/stripe",
    twitterGenerate: "/tools/x-revenue",
    fbDownload: "tools/fb-extract",
  },
};

export const API_ROUTE = {
  keyword: {
    readSiteMap: "/api/cms/keyword-research/read-sitemap",
    readMetaData: "/api/cms/keyword-research/read-site-meta",
  },
};

import { cx } from "src/utils/all";
import Image from "next/image";

interface IProps {
  alt: string;
  url: string;
  sizes?: string;
  style?: any;
  className?: string;
}

const ImageComponent = ({ alt, url, sizes, style, className }: IProps) => {
  const defaultImage = "https://illustrations.popsy.co/gray/web-design.svg";
  return (
    <Image
      src={url || defaultImage}
      alt={alt}
      fill
      className={cx("transition-all", className)}
      sizes={sizes}
      style={style}
      blurDataURL="data:image/webp;base64,AAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAAA="
      placeholder="blur"
    />
  );
};

export default ImageComponent;

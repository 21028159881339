import PlaceholderCard from "./placeholder-card.component";

const LoadingComponent = ({ length = 8 }) => {
  return (
    <div className="my-8 grid grid-cols-1 gap-4 sm:grid-cols-2 lg:grid-cols-4">
      {Array.from({ length }).map((_, i) => (
        <PlaceholderCard key={i} />
      ))}
    </div>
  );
};

export default LoadingComponent;
